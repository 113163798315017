import {EventEmitter, Injectable, Output, OnInit} from '@angular/core';
import { AuthService as ngAuth } from 'ng2-ui-auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() status: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private auth: ngAuth) {

  }

  login(user) {
    const login$ = this.auth.login(user);
    login$.subscribe(() => {
      this.status.emit(true);
    }, () => {
      return false;
    });
    return login$;
  }

  logout() {

    localStorage.clear();

    const logout$ = this.auth.logout();
    logout$.subscribe(() => {
      this.status.next(false);
    });
    return logout$;
  }


  setToken(token) {
    this.auth.setToken(token);
  }

  getToken() {
    return this.auth.getToken();
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }


}
