import { Component, OnInit } from '@angular/core';
import {PurchaseService} from '../../services/purchase.service';

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss']
})
export class CheckoutSuccessComponent implements OnInit {

  public purchaseId: number;

  constructor(public purchase: PurchaseService) {

  }

  ngOnInit() {

    this.purchaseId = this.purchase.get('id');

    this.purchase._init();

  }

}
