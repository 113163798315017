
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from '../../services/context.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  title = 'Masterbus Tickets';
  me: any;
  navbarCollapsed = true;

  constructor (
    private context: ContextService,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.context.me$.subscribe((user) => {
      this.me = user;
    });
  }

  logout() {
    this.navbarCollapsed = true;
    this.auth.logout().subscribe({
      error: (err: any) => alert(err.message),
      complete: () => this.router.navigateByUrl('login')
    });
  }

}
