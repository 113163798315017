import { Component, OnInit } from '@angular/core';
import {PurchaseService} from '../../services/purchase.service';
import {Restangular} from 'ngx-restangular';
import {AuthService} from 'ng2-ui-auth';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tickets-destination',
  templateUrl: './tickets-destination.component.html',
  styleUrls: ['./tickets-destination.component.css']
})
export class TicketsDestinationComponent implements OnInit {

  Routes: any
  destinations: any

  constructor(private auth: AuthService,
              private router: Router,
              private API: Restangular,
              private purchase: PurchaseService) {

    this.Routes = this.API.service('public/routes');

  }

  ngOnInit() {
    this.purchase.checkStepAndNavigateIfInvalid('destination');
    this.getDestinations();
  }

  getDestinations() {

    this.Routes
      .one(this.purchase.get('route').id)
      .customGET('stops', { type: 'destination' })
      .subscribe(response => {
        this.destinations = response.stops;
      });

  }

  selectDestination(destination) {

    this.purchase
      .set('destination', destination)
      .navigateToNextStep();

  }


}
