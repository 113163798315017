import { Component, OnInit } from '@angular/core';
import {PurchaseService} from '../../services/purchase.service';
import {Restangular} from 'ngx-restangular';
import {AuthService} from 'ng2-ui-auth';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tickets-time',
  templateUrl: './tickets-time.component.html',
  styleUrls: ['./tickets-time.component.css']
})
export class TicketsTimeComponent implements OnInit {

  Routes: any
  schedules: any
  origin: any

  constructor(private auth: AuthService,
              private router: Router,
              private API: Restangular,
              private purchase: PurchaseService) {

    this.Routes = this.API.service('public/routes');

  }

  ngOnInit() {
    this.purchase.checkStepAndNavigateIfInvalid('time');
    this.getTimes();
    this.origin = this.purchase.get('origin');
  }

  getTimes() {

    this.Routes
      .one(this.purchase.get('route').id)
      .one('stops', this.purchase.get('origin').id)
      .one('to', this.purchase.get('destination').id)
      .all('times')
      .getList()
      .subscribe(response => this.schedules = response);

  }

  selectTime(time) {

    this.purchase
      .set('time', time)
      .navigateToNextStep();

  }


}
