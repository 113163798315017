import { Component, OnInit } from '@angular/core';
import { Restangular} from 'ngx-restangular';
import { Router, ActivatedRoute, ParamMap} from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-purchases-show',
  templateUrl: './purchases-show.component.html',
  styleUrls: ['./purchases-show.component.scss']
})
export class PurchasesShowComponent implements OnInit {

  private Purchase: any;
  public purchase: any;

  constructor(
    private API: Restangular,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.Purchase = this.API.service('purchases');
    this.getPurchase(this.route.snapshot.paramMap.get('id'));

  }

  getPurchase(id) {

    this.Purchase
      .one(id)
      .get()
      .subscribe((response) => {
        this.purchase = response.purchase;
      });

  }

}
