import { Component, OnInit } from '@angular/core';
import {PurchaseService} from '../../services/purchase.service';
import {Restangular} from 'ngx-restangular';
import {AuthService} from 'ng2-ui-auth';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tickets-origin',
  templateUrl: './tickets-origin.component.html',
  styleUrls: ['./tickets-origin.component.css']
})
export class TicketsOriginComponent implements OnInit {

  Routes: any
  origins: any

  constructor(private auth: AuthService,
              private router: Router,
              private API: Restangular,
              private purchase: PurchaseService) {

    this.Routes = this.API.service('public/routes');

  }

  ngOnInit() {
    this.purchase.checkStepAndNavigateIfInvalid('origin');
    this.getOrigins();
  }

  getOrigins() {

    const route = this.purchase.get('route');

    this.Routes
      .one(route.id)
      .customGET('stops', { type: 'origin' })
      .subscribe(response => {
        this.origins = response.stops;
      });

  }

  selectOrigin(origin) {

    this.purchase
      .set('origin', origin)
      .navigateToNextStep();

  }


}
