import { Component, OnInit } from '@angular/core';
import { PurchaseDiscountInterface, PurchaseService } from '../../services/purchase.service';
import {Restangular} from 'ngx-restangular';

@Component({
  selector: 'app-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.scss']
})
export class CheckoutSummaryComponent implements OnInit {

  public route: any = {};
  public dates: any = {};
  public time: any = {};
  public quantity: any = {};
  public discount: PurchaseDiscountInterface;
  public amount: number;
  public fullAmount: number;
  public averageTicketPrice: number;
  public showTripsSummary = false;

  constructor(public purchase: PurchaseService,
              public API: Restangular) { }

  ngOnInit() {

    this.purchase.data$
      .subscribe((data) => {
        this.route = data.route;
        this.dates = data.dates;
        this.time = data.time;
        this.quantity = data.quantity;
        this.discount = data.discount;
        this.calculate(data.discount);
      });

  }

  calculate(discount) {

      this.fullAmount = discount ? discount.fullAmount : this.calculateAmount();
      this.amount = discount ? discount.amountWithDiscount : this.fullAmount;
      this.averageTicketPrice = this.fullAmount / (this.quantity * this.dates.trips.length);

  }

  calculateAmount() {

    let totalPrice = 0;
    this.dates.trips.forEach((trip) => {
      totalPrice += parseFloat(trip.price);
    });
    return totalPrice * this.quantity;

  }
}
