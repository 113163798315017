import { Component, OnInit } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  password: string;
  password_confirmation: string;

  constructor(private API: Restangular,
              private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService) { }

  ngOnInit() {


  }

  reset(form) {

    if (form.invalid) {
      return;
    }

    const data = {
      email: this.route.snapshot.paramMap.get('email'),
      token: this.route.snapshot.paramMap.get('token'),
      password: this.password,
      password_confirmation: this.password_confirmation,
    };

    this.API
      .all('auth/password/reset')
      .post(data)
      .subscribe(() => {
        this.toastr.success('Cambiaste tu contraseña con éxito. Ya podés ingresar con la nueva contraseña.', 'Buenísimo!');
        this.router.navigate(['/login']);
    }, (res) => {
        _.each(res.data.errors, (value) => {
          this.toastr.error(value[0], 'Error!');
        });
    });


  }

}
