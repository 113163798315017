import { Component, OnInit } from '@angular/core';
import {PurchaseService} from '../../services/purchase.service';

@Component({
  selector: 'app-tickets-quantity',
  templateUrl: './tickets-quantity.component.html',
  styleUrls: ['./tickets-quantity.component.scss']
})
export class TicketsQuantityComponent implements OnInit {

  quantity: number;

  constructor(private purchase: PurchaseService) { }

  ngOnInit() {
    this.quantity = 1;
  }

  selectQuantity(quantity) {

    this.purchase
      .set('quantity', quantity)
      .navigateToNextStep();

  }

}
