import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'routeName'
})
export class RouteNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(new RegExp('->', 'g'), '<span class="fa fa-angle-right"></span>');
  }

}
