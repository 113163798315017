import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbDateStruct, NgbCalendar, NgbDate, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {PurchaseService} from '../../services/purchase.service';
import {Restangular} from 'ngx-restangular';
import {Router} from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-tickets-date',
  templateUrl: './tickets-date.component.html',
  styleUrls: ['./tickets-date.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TicketsDateComponent implements OnInit {

  @ViewChild( 'dp') dp;

  Routes: any;
  date: NgbDateStruct;
  startDate: NgbDateStruct;
  minDate: NgbDateStruct;

  trips: any[];
  showDatepicker: boolean;

  fromDate: NgbDate;
  toDate: NgbDate;

  weekdaysSelected: any;

  periodic: boolean;

  currentMonth: { year: number, month: number };

  constructor(private router: Router,
              private API: Restangular,
              private purchase: PurchaseService,
              private calendar: NgbCalendar,
              private i18n: NgbDatepickerI18n) { }

  ngOnInit() {

    this.purchase.checkStepAndNavigateIfInvalid('dates');

    const dataDates = this.purchase.get('dates')

    if (!dataDates) {
      this.periodic = false;
      this.weekdaysSelected = { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, };
    } else {
      this.periodic = !!dataDates.periodic;
      this.fromDate = dataDates.fromDate;
      this.toDate = dataDates.toDate;
      this.weekdaysSelected = dataDates.weekdays;
    }

    this.minDate = this.calendar.getToday();
    this.startDate = this.calendar.getToday();

    this.showDatepicker = false;
    this.Routes = this.API.service('public/routes');
    this.getTrips()
      .then((trips) => {
        this.showDatepicker = true;
    });


  }

  getTrips() {

    return new Promise((resolve, reject) => {

      const route = this.purchase.get('route');
      const time = this.purchase.get('time');
      const month = this.calendar.getToday().month;
      const fromStop = this.purchase.get('origin').id;

      this.Routes
        .one(route.id)
        .all('trips')
        .getList({ schedule: time.id, month: month, time: time.time, fromStop: this.purchase.get('origin').id })
        .subscribe(response => {
          this.trips = response;
          resolve(this.trips);
        });

    });

  }

  onDateSelection(date: NgbDate) {

    if (this.periodic) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
        this.toDate = date;
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
    } else {
      this.date = date;
      this.fromDate = date;
      this.toDate = date;
    }

    this.computeDates();
  }

  onWeekdaysChange() {
    this.computeDates();
  }

  isDisabled = (date: NgbDateStruct) => {

    let disabled = true;
    _.each(this.trips, (trip) => {
      if (date.year === parseInt(trip.date.substr(0, 4), 10)
        &&  date.month === parseInt(trip.date.substr(5, 2), 10)
        &&  date.day === parseInt(trip.date.substr(8, 2), 10)
        &&  trip.available >= this.purchase.get('quantity')) {
        disabled = false;
      }
    });

    return disabled;
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date);
  }

  isSelected(date: NgbDate) {
    return this.isWeekdaySelected(date) && this.isRange(date);
  }

  isPast(date: NgbDate) {
    return date.before(this.calendar.getToday());
  }

  isWeekdaySelected(date: NgbDate) {
    return this.periodic && this.weekdaysSelected[this.calendar.getWeekday(date)];
  }

  computeDates() {
    let iDate = new NgbDate(this.fromDate.year, this.fromDate.month, this.fromDate.day);
    const selectedDates = [];
    const selectedTrips = []

    while (iDate.before(this.toDate) || iDate.equals(this.toDate)) {
      if ((this.isSelected(iDate) && !this.isDisabled(iDate)) || !this.periodic) {
        selectedDates.push(iDate);
        selectedTrips.push(this.getTripByDate(iDate));
      }
      iDate = this.calendar.getNext(iDate, 'd');
    }

    const dates = {
      selected: selectedDates,
      weekdays: this.weekdaysSelected,
      fromDate: this.fromDate,
      toDate: this.toDate,
      periodic: this.periodic,
      trips: selectedTrips
    };

    this.purchase.set('dates', dates);

  }

  getTripByDate(date: NgbDate) {

    const trips = this.trips.filter((trip) => {
        return (date.year === parseInt(trip.date.substr(0, 4), 10)
          &&  date.month === parseInt(trip.date.substr(5, 2), 10)
          &&  date.day === parseInt(trip.date.substr(8, 2), 10));
    });

    return trips[0] || null;

  }

  changePeriodic() {
    this.onDateSelection(this.fromDate);
  }

  getMonthFullName(date) {
    if (!date) {
      return null;
    }
    return this.i18n.getMonthFullName(date.month, date.year);
  }

  nextMonth() {
    if (this.currentMonth.month === 12) {
      this.currentMonth.year++;
      this.currentMonth.month = 0;
    }
    this.dp.navigateTo({ year: this.currentMonth.year, month: this.currentMonth.month + 1 });
  }

  previousMonth() {
    if (this.currentMonth.month === 1) {
      this.currentMonth.year--;
      this.currentMonth.month = 13;
    }
    this.dp.navigateTo({ year: this.currentMonth.year, month: this.currentMonth.month - 1 });
  }

  isCurrentMonth(month) {

    if (!month) {
      return false;
    }
    return (month.year === this.calendar.getToday().year && month.month === this.calendar.getToday().month);

  }

}
