import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { TicketsRouteComponent } from './components/tickets-route/tickets-route.component';
import { TicketsOriginComponent } from './components/tickets-origin/tickets-origin.component';
import { TicketsDestinationComponent } from './components/tickets-destination/tickets-destination.component';
import { TicketsTimeComponent } from './components/tickets-time/tickets-time.component';
import { TicketsDateComponent } from './components/tickets-date/tickets-date.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CheckoutSuccessComponent } from './components/checkout-success/checkout-success.component';
import { PurchasesComponent } from './components/purchases/purchases.component';
import { PurchasesShowComponent } from './components/purchases-show/purchases-show.component';
import { PurchasesShowTicketComponent } from './components/purchases-show-ticket/purchases-show-ticket.component';
import { TicketsQuantityComponent } from './components/tickets-quantity/tickets-quantity.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MyTicketsComponent } from './components/my-tickets/my-tickets.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import {HomeComponent} from './components/home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent, },
  { path: 'forgot-password', component: ForgotPasswordComponent, },
  { path: 'reset-password/:email/:token', component: ResetPasswordComponent, },
  { path: 'tickets', component: TicketsComponent, canActivate: [AuthGuard], children: [
      { path: 'route', component: TicketsRouteComponent },
      { path: 'origin', component: TicketsOriginComponent },
      { path: 'destination', component: TicketsDestinationComponent },
      { path: 'time', component: TicketsTimeComponent },
      { path: 'dates', component: TicketsDateComponent },
      { path: 'quantity', component: TicketsQuantityComponent },
    ],
  },
  { path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard], },
  { path: 'checkout/success', component: CheckoutSuccessComponent, canActivate: [AuthGuard], },
  { path: 'purchases', component: PurchasesComponent, canActivate: [AuthGuard], },
  { path: 'purchases/:id', component: PurchasesShowComponent, canActivate: [AuthGuard], },
  { path: 'my-tickets', component: MyTicketsComponent, canActivate: [AuthGuard], },
  { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard], },
  { path: 'tickets/:id', component: PurchasesShowTicketComponent, canActivate: [AuthGuard], },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
