import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { PurchaseService } from '../../services/purchase.service';
import {ContextService} from '../../services/context.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  user: any = {};
  errorMessage: any = null;

  constructor(private auth: AuthService,
              private router: Router,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private purchase: PurchaseService,
              private context: ContextService) { }

  onSubmit(form) {

    this.spinner.show();
    this.auth
      .login(this.user)
      .subscribe({
        next: (response: any) => {
          this.context.setMe(response.data.user);
          this.purchase._init();
        },
        error: (response: any) => {
          if (response.status === 422) {
            this.errorMessage = 'Esas credenciales no concuerdan con nuestros registros.';
          } else {
            this.errorMessage = response.error.errors.message.join('. ');
          }
          this.spinner.hide();
          return false;
        },
        complete: () => {
          this.router.navigateByUrl('/tickets/route');
          this.spinner.hide();
          return false;
        }
      });


  }

}
