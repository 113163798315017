import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';


@Injectable({
  providedIn: 'root'
})
export class ContextService {

  protected User: any;

  private _userData = new BehaviorSubject<any>(null);

  me$ = this._userData.asObservable();

  constructor (
    private auth: AuthService,
    private API: Restangular,
    protected localStorage: LocalStorage) {

    this.User = this.API.service('me', this.API.all('users'));

    this.auth.status
      .subscribe((loggedIn) => {
        if (!loggedIn) {
          this._userData.next(null);
        }
      });

    localStorage.getItem('user').subscribe((user) => {
      if (user) {
        this.setMe(user);
      }
    });

  }

  setMe(me) {
    this._userData.next(me);

    this.localStorage.setItem('user', me).subscribe(() => {
    });
  }

  getMe() {
    return this.User.one().get().subscribe((response) => {
      this._userData.next(response);
    });
  }

  cards() {
    return this.User.all('payment_methods').getList();
  }

}
