import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'ng2-ui-auth';
import { PurchaseService } from '../../services/purchase.service';
import {WeekDay} from '@angular/common';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketsComponent implements OnInit {

  data: any;

  constructor(private router: Router,
              private purchase: PurchaseService) {

  }

  ngOnInit() {
    this.purchase.all().subscribe((data) => this.data = data );
  }

  reset() {
    this.purchase
      ._init()
      .navigateToNextStep();
  }

}
