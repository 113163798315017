import { Component, OnInit } from '@angular/core';
import {ContextService} from '../../services/context.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Restangular} from 'ngx-restangular';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  me: any;
  show = false;

  profileForm: FormGroup;
  passwordForm: FormGroup;

  profileFormSubmitted = false;
  passwordFormSubmitted = false;

  constructor(private context: ContextService,
              private formBuilder: FormBuilder,
              private API: Restangular,
              private toastr: ToastrService) { }

  ngOnInit() {

    this.context.me$.subscribe((user) => {

      if (user) {
        this.me = user;
        this.show = true;
        this.profileForm = this.formBuilder.group({
          phone: [this.me.phone]
        });
      }

    });

    this.passwordForm = this.formBuilder.group({
      password: ['', [<any>Validators.required, <any>Validators.minLength(6), <any>Validators.maxLength(50)]],
      passwordConfirmation: ['', [<any>Validators.required]]
    }, { validator: this.checkPasswords });

  }

  checkPasswords(group: FormGroup) {
    return group.controls.password.value === group.controls.passwordConfirmation.value ? null : { notSame: true };
  }

  get f1() { return this.profileForm.controls; }
  get f2() { return this.passwordForm.controls; }

  saveProfile(form) {
    this.profileFormSubmitted = true;
    this.save({
      phone: this.profileForm.get('phone').value,
    });
  }

  savePassword(form) {
    this.passwordFormSubmitted = true;
    this.save({
      password: this.passwordForm.get('password').value,
      password_confirmation: this.passwordForm.get('passwordConfirmation').value,
    });
  }

  save(data) {

    this.API
      .service('users')
      .one('me')
      .customPUT(data)
      .subscribe((res) => {
        this.toastr.success('Información actualizada con éxito.', '¡Buenísimo!');
        this.context.setMe(res.user);
      });

  }

}
