import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'ng2-ui-auth';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  user: any = {};

  constructor(private auth: AuthService,
              private router: Router,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modal: NgbModal) { }

  onSubmit(form) {

    _.each(form.elements, (element) => {
      element.setErrors({ servererror: null});
    });

    if (!form.valid) {
      return;
    }

    this.spinner.show();
    this.auth
      .signup(this.user)
      .subscribe({
        next: (response: any) => {
          this.auth.setToken(response.access_token);
        },
        error: (response: any) => {
          this.spinner.hide();
          _.each(response.error.errors, (messages, field) => {
            form.controls[field].setErrors({ servererror: messages.join('. ') });
          });
        },
        complete: () => {
          this.spinner.hide();
          this.toastr.success(
            'Para terminar con tu proceso de registro, hemos enviado un email a ' +
            this.user.email +
            ' con instrucciones para verificar la dirección.',
            'Muy bien!', {
              closeButton: true,
              disableTimeOut: true,
              tapToDismiss: false,
              positionClass: 'toast-center-center',
          });
          this.router.navigateByUrl('login');

        }
      });

  }

  openTOS(content) {

    this.modal.open(content, { centered: true });

  }

}
