import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { PaymentService } from '../../services/payment.service';
import {ContextService} from '../../services/context.service';
import {Restangular} from 'ngx-restangular';
import {PurchaseService} from '../../services/purchase.service';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {CreditCard, CreditCardValidator} from 'angular-cc-library';
import { FormGroup, FormBuilder, FormControl, FormArray, NgForm, Validators} from '@angular/forms';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // providers: [FormBuilder],
})
export class CheckoutComponent implements OnInit {

  @ViewChild('content') content: ElementRef;

  errorMessage: string;
  paymentMethods: any;
  payForm: FormGroup;
  submitted = false;
  processing = false;
  cardType: string;
  cardImage: string;

  payment: any = {
    name: '',
    dni: '',
    email: '',
  };

  constructor(private payments: PaymentService,
              private purchase: PurchaseService,
              private context: ContextService,
              private API: Restangular,
              private router: Router,
              private modalService: NgbModal,
              private formBuilder: FormBuilder) {

  }

  ngOnInit() {

    this.payForm = this.formBuilder.group({
      cardId: [''],
      cardholderName: [''],
      email: [''],
      docType: ['DNI'],
      docNumber: [''],
      cardNumber: [''],
      cardExpiration: [''],
      cardExpirationMonth: [''],
      cardExpirationYear: [''],
      securityCode: ['', [<any>Validators.required, <any>Validators.minLength(3), <any>Validators.maxLength(4)]],
    });

    this.payForm
      .get('cardId')
      .valueChanges
      .subscribe(this.setConditionalValidators);

    this.getCards();
    this.payments.init();

  }

  setConditionalValidators = (cardId) => {

    const othersRequired = !!(cardId === '');

    if (othersRequired) {
      this.payForm.get('cardholderName').setValidators([Validators.required]);
      this.payForm.get('email').setValidators([Validators.required]);
      this.payForm.get('docNumber').setValidators([
        <any>Validators.required,
        <any>Validators.min(100000),
        <any>Validators.max(99999999)
      ]);
      this.payForm.get('cardNumber').setValidators(<any>CreditCardValidator.validateCCNumber);
      this.payForm.get('cardExpiration').setValidators([<any>Validators.required, <any>CreditCardValidator.validateExpDate]);
    } else {

      const currentCard = this.paymentMethods.find(card => card.id_mercadopago === cardId);
      this.cardType = currentCard.brand;
      this.updateSecurityCodeValidators(this.cardType === 'amex' ? 4 : 3);

      this.payForm.get('cardholderName').setValidators(null);
      this.payForm.get('email').setValidators(null);
      this.payForm.get('docNumber').setValidators(null);
      this.payForm.get('cardNumber').setValidators(null);
      this.payForm.get('cardExpiration').setValidators(null);
    }

    this.payForm.get('cardholderName').updateValueAndValidity();
    this.payForm.get('email').updateValueAndValidity();
    this.payForm.get('docNumber').updateValueAndValidity();
    this.payForm.get('cardNumber').updateValueAndValidity();
    this.payForm.get('cardExpiration').updateValueAndValidity();

  }

  updateSecurityCodeValidators(cardSecurityCodeLength) {
    this.payForm.get('securityCode').setValidators([
      <any>Validators.required,
      <any>Validators.minLength(cardSecurityCodeLength),
      <any>Validators.maxLength(cardSecurityCodeLength)
    ]);
    this.payForm.get('securityCode').updateValueAndValidity();
  }

  get f() { return this.payForm.controls; }

  get creatingNewCard() { return !(this.payForm.controls.cardId.value && this.payForm.controls.cardId.value !== ''); }

  getCards() {

    this.context
      .cards()
      .subscribe(response => {
        this.paymentMethods = response;
        if (this.paymentMethods.length) {
          this.payForm.controls.cardId.setValue(response[0].id_mercadopago);
        }
        this.setConditionalValidators(this.payForm.controls.cardId.value);
      });
  }

  updateCardType() {

    const card = CreditCard.cardFromNumber(this.payForm.get('cardNumber').value);
    this.cardType = card ? card.type : undefined;

    const bin = this.payForm.get('cardNumber').value.replace(/[^0-9]/g, '').substring(0, 6);

    if (bin.length >= 6) {
      this.payments.getPaymentMethod(bin).then((r) => {
        if (r['status'] === 200 && r['data'].length) {
          this.cardType = r['data'][0].id;
          this.cardImage = r['data'][0].secure_thumbnail;
          this.updateSecurityCodeValidators(r['data'][0].settings[0].security_code.length);
        }
      });
    } else {
      this.cardType = undefined;
      this.cardImage = undefined;
    }

  }

  pay(form) {

    this.submitted = true;

    if (form.invalid) {
      return false;
    }

    this.processing = true;

    let cardExpirationMonth, cardExpirationYear;
    [cardExpirationMonth, cardExpirationYear] = form.controls.cardExpiration.value.split(' / ');
    form.controls.cardExpirationMonth.setValue(cardExpirationMonth);
    form.controls.cardExpirationYear.setValue(cardExpirationYear);
    form.controls.cardExpiration.setValue(cardExpirationMonth + '/' + cardExpirationYear);

    const p = this.payForm.get('cardId').value !== ''
      ? this.payments.createToken(form)
      : this.payments.registerPaymentMethod(form);

    p.subscribe((gatewayData) => {

      gatewayData['card_id'] = form.controls.cardId.value;
      this.API
        .service('payments')
        .post({
          gatewayData: gatewayData,
          paymentData: this.payment,
          purchaseData: this.purchase.get()
        })
        .subscribe((response) => {

          if (response.purchase.status === 'approved') {
            this.purchase.set('id', response.purchase.id);
            this.router.navigate(['checkout/success']);
          }

        }, (response) => {
          this.processing = false;
          if (response.status === 400) {
            this.errorMessage = response.data.message;
          } else {
            this.errorMessage = 'Lo sentimos, no pudimos procesar tu pago. Por favor, intentá nuevamente en unos minutos.';
          }
          this.openModal();
        });
    });

  }

  openModal() {
    this.modalService
      .open(this.content, { windowClass: 'modal-error' })
      .result
      .then(() => {
        window.location.reload();
      });
  }

}
