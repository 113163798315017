import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from './services/context.service';
import {AuthService} from 'ng2-ui-auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Masterbus Tickets';
  me: any;
  navbarCollapsed = true;

  constructor (
    private context: ContextService,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit() {

  }

}
