import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModule } from 'ngx-bootstrap';
import { AppRoutingModule } from './app-routing.module';

import { RestangularModule } from 'ngx-restangular';
import { RestangularConfigFactory } from '../config/restangular.config';
import { Ng2UiAuthModule} from 'ng2-ui-auth';
import { authConfig } from '../config/auth.config';

import { AppComponent } from './app.component';
import { RegisterComponent } from './components/register/register.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';

import { TicketsComponent } from './components/tickets/tickets.component';
import { LoginComponent } from './components/login/login.component';
import { PurchaseService } from './services/purchase.service';
import { TicketsRouteComponent } from './components/tickets-route/tickets-route.component';
import { TicketsOriginComponent } from './components/tickets-origin/tickets-origin.component';
import { TicketsDestinationComponent } from './components/tickets-destination/tickets-destination.component';
import { TicketsDateComponent } from './components/tickets-date/tickets-date.component';
import { TicketsTimeComponent } from './components/tickets-time/tickets-time.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CheckoutSuccessComponent } from './components/checkout-success/checkout-success.component';
import { PurchasesComponent } from './components/purchases/purchases.component';
import { PurchasesShowComponent } from './components/purchases-show/purchases-show.component';
import { PurchasesShowTicketComponent } from './components/purchases-show-ticket/purchases-show-ticket.component';
import { EqualsValidatorDirective } from './shared/equals-validator.directive';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RouteNamePipe } from './pipes/route-name.pipe';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CheckoutSummaryComponent } from './components/checkout-summary/checkout-summary.component';
import { TicketsQuantityComponent } from './components/tickets-quantity/tickets-quantity.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HttpClientModule } from '@angular/common/http';
import { MyTicketsComponent } from './components/my-tickets/my-tickets.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { FooterComponent } from './components/footer/footer.component';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';


import * as Sentry from '@sentry/browser';
import { HomeComponent } from './components/home/home.component';
import { environment } from '../environments/environment';
import { CheckoutCouponComponent } from './components/checkout-coupon/checkout-coupon.component';

Sentry.init({
  dsn: 'https://59de3c0bfbe047659707e2248f96932e@sentry.io/1387701',
  environment: environment.name,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

localeEsAr[14][2] = '¤#,##0.00';
registerLocaleData(localeEsAr);


@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    TicketsComponent,
    LoginComponent,
    TicketsRouteComponent,
    TicketsOriginComponent,
    TicketsDestinationComponent,
    TicketsDateComponent,
    TicketsTimeComponent,
    CheckoutComponent,
    CheckoutSuccessComponent,
    PurchasesComponent,
    PurchasesShowComponent,
    PurchasesShowTicketComponent,
    EqualsValidatorDirective,
    RouteNamePipe,
    NavbarComponent,
    CheckoutSummaryComponent,
    TicketsQuantityComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MyTicketsComponent,
    UserProfileComponent,
    FooterComponent,
    HomeComponent,
    CheckoutCouponComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RestangularModule.forRoot([NgxSpinnerService], RestangularConfigFactory),
    AppRoutingModule,
    AlertModule.forRoot(),
    Ng2UiAuthModule.forRoot(authConfig),
    NgbModule,
    CreditCardDirectivesModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'toast-center-center',}),
    HttpClientModule,
    LeafletModule.forRoot()
  ],
  providers: [
    PurchaseService,
    { provide: LOCALE_ID, useValue: 'es-AR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
