import { Directive, Input } from '@angular/core';
import {Validator, AbstractControl, ValidationErrors, NG_VALIDATORS} from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appEquals]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EqualsValidatorDirective, multi: true }]
})

export class EqualsValidatorDirective implements Validator {
  @Input('appEquals') controlNameToCompare: string;

  validate(c: AbstractControl): ValidationErrors | null {
    const controlToCompare = c.root.get(this.controlNameToCompare);
    if (controlToCompare) {
      const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
        c.updateValueAndValidity();
        subscription.unsubscribe();
      });
    }
    return controlToCompare && controlToCompare.value !== c.value ? { 'notequal': true }: null;
  }

}
