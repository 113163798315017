import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Headers} from '@angular/http';
import {FormGroup} from '@angular/forms';


declare let Mercadopago: any;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private API: Restangular,
              private http: HttpClient) {
  }

  init() {
    this._load_js();
  }

  _load_js() {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = 'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js';
    script.onload = this.jsReady;
    head.appendChild(script);
  }

  jsReady() {
    Mercadopago.setPublishableKey(environment.mercadoPagoPublicKey);
    console.log('MercadoPago set');
  }

  getIdentificationTypes() {

    return new Promise((resolve, reject) => {
      Mercadopago.getIdentificationTypes((status, data) => {
        if (status === 200 || status === 201) {
          resolve({ status: status, data: data });
        } else {
          reject({ status: status, data: data });
        }
      });
    });

  }

  getPaymentMethod(bin) {

    return new Promise((resolve, reject) => {
      Mercadopago.getPaymentMethod({
        bin: bin
      }, (status, data) => {
        if (status === 200 || status === 201) {
          resolve({ status: status, data: data });
        } else {
          reject({ status: status, data: data });
        }
      });
    });

  }

  createToken(form) {
    return new Observable(observer => {
      Mercadopago.createToken(this.formGroupToObject(form),
        function success(status, data) {
          console.log('Got token ', data);
          observer.next(data);
        },
        function error(status, data) {
          observer.error(data);
        });
    });
  }

  postCardToApi = (gatewayData) => {
    console.log('Posting card to API, token: ', gatewayData.id);
    return this.API
      .service('users/me/payment_methods')
      .post({ token: gatewayData.id });
  }

  reCreateToken = (apiResponse, form) => {
    form.controls.cardId.setValue(apiResponse.id_mercadopago);
    return this.createToken(form);
  }

  registerPaymentMethod(form) {
    return this
      .createToken(form)
      .pipe(switchMap(this.postCardToApi))
      .pipe(switchMap((response) => this.reCreateToken(response, form)));
  }

  formGroupToObject (formGroup) {
    const object = {};
    Object.keys(formGroup.controls).forEach((n) => {
      console.log('card Id = ', formGroup.get('cardId').value);
      if ( formGroup.get('cardId').value === '' && n === 'cardId') {
        return;
      } else if (formGroup.get('cardId').value !== ''
        && ( n === 'cardholderName'
          || n === 'docType'
          || n === 'docNumber'
          || n === 'email'
          || n === 'cardNumber'
          || n === 'cardExpirationMonth'
          || n === 'cardExpirationYear'
          || n === 'cardExpiration' )) {
        return;
      }
      object[n] = formGroup.get(n).value;
    })
    console.log('Card Object', object);
    return object;
  }


}
