import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Restangular } from 'ngx-restangular';
// import { LocalStorage } from '@ngx-pwa/local-storage';

export interface PurchaseDiscountInterface {
  fullAmount: number,
  amountWithDiscount: number,
  discount: number,
  discountReason: 'coupon' | 'quantity',
  couponCode?: string,
}


@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  private data: {
    route: any,
    origin: any,
    destination: any,
    quantity: number,
    time: any
    dates: any,
    discount: any,
  };

  private _data = new BehaviorSubject<any>(this.data);

  data$ = this._data.asObservable();

  constructor(private router: Router,
              private API: Restangular) {

    const data = JSON.parse(localStorage.getItem('purchase'));

    if (data === null) {
      this._init();
    } else {
      this.data = data;
      this._data.next(Object.assign({}, this.data));
    }

  }

  public getData(): Observable<number[]> {
    return this.data$;
  }

  _init(save = true) {

    this.data = {
      route: null,
      origin: null,
      destination: null,
      quantity: null,
      time: null,
      dates: null,
      discount: null,
    };

    return save ? this._save() : this;

  }

  _save() {
    this._data.next(this.data);
    localStorage
      .setItem('purchase', JSON.stringify(this.data));
    return this;
  }

  all() {
    return this._data.asObservable();
  }

  get(field = null) {
    const data = JSON.parse(localStorage.getItem('purchase'));
    if (!field) {
      return data;
    }
    return data[field];
  }

  set(field, value) {
    if (field === 'route') {
      this._init(false);
    }
    if (field === 'time') {
      this.data.dates = null;
    }
    if (field === 'dates') {
     this.setDates(value);
    } else {
      this.data[field] = value;
    }
    this._save();
    return this;
  }

  nextStep() {
    let next;
    _.each(this.data, (value, key) => {
      if (value === null) {
        next = key;
        return false;
      }
    });
    return next || 'checkout';
  }

  checkStep(step) {

    let valid = true;
    _.each(this.data, (value, key) => {
      if (key === step) {
        return false;
      }
      if (value === null) {
        valid = false;
      }
    });
    return valid;

  }

  checkStepAndNavigateIfInvalid(step) {
    if (!this.checkStep(step)) {
      this.navigateToNextStep();
    }
  }

  navigateToNextStep() {
    const nextStep = this.nextStep();
    const url = nextStep === 'checkout' ? 'checkout' : 'tickets/' + nextStep;
    this.router.navigate([url]);
  }

  setDates(value) {

    const weekdaysSelected = value.weekdays;
    const weekdays = ['', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábados', 'Domingos', ];
    this.data.dates = value;
    this.data.dates.weekdaysSelectedNames = [];

    _.each(weekdaysSelected, (v, k) => {
      if (v) {
        this.data.dates.weekdaysSelectedNames.push(weekdays[k]);
      }
    });

  }

  getDiscount(couponCode?: string) {

    const params = {
      routeId: this.get('route').id,
      quantity: this.get('quantity'),
      dates: JSON.stringify(this.get('dates').selected),
      schedule: this.get('time').id
    };

    if (couponCode) {
      params['couponCode'] = couponCode;
    }

    return this.API.all('purchases').customGET('discount', params);
  }

}
