// Function for setting the default restangular configuration
import { environment } from '../environments/environment';
import { Ng2UiAuthModule } from 'ng2-ui-auth';
import { HttpClientModule } from '@angular/common/http';

export function RestangularConfigFactory (RestangularProvider, spinner) {

  RestangularProvider.setBaseUrl(environment.apiUrl);
  RestangularProvider.setDefaultHeaders({'Authorization': 'Bearer ' + localStorage.getItem('ng2-ui-auth_token')});

  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
    spinner.show();
    const token = localStorage.getItem('ng2-ui-auth_token');
    return {
      headers: Object.assign({}, headers, {Authorization: `Bearer ${token}`})
    };
  });

  RestangularProvider.addResponseInterceptor((data, operation, what, url, response) => {
    spinner.hide();
    return data.data;
  });

  RestangularProvider.addErrorInterceptor((r) => {
    spinner.hide();
    return true;
  });

  // RestangularProvider.addErrorInterceptor((data, operation, what, url, response) => {
  //
  //   console.log(response.status, response);
  //
  //   if(response.status === 401) {
  //
  //     HttpClientModule
  //       .get<string>('/auth/refresh')
  //       .pipe(tap(token => this.auth.setToken(token)))
  //       .subscribe({
  //         error: (err: any) => alert(err.message),
  //         complete: () => ($http(response.config).then(responseHandler, deferred.reject);)
  //       });
  //
  //     });
  //
  //     return false; // error handled
  //   }
  //
  //   return data;
  //
  // });

}
