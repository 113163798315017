import { Component, OnInit } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent implements OnInit {

  public purchases: any[];

  constructor(private API: Restangular) { }

  ngOnInit() {

    this.getPurchases();

  }

  getPurchases() {

     this.API
       .service('purchases')
       .getList()
       .subscribe(response => {
         this.purchases = response;
       });


  }

}
