import { Component, OnInit } from '@angular/core';
import { Restangular} from 'ngx-restangular';
import { Router, ActivatedRoute, ParamMap} from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-purchases-show-ticket',
  templateUrl: './purchases-show-ticket.component.html',
  styleUrls: ['./purchases-show-ticket.component.scss']
})
export class PurchasesShowTicketComponent implements OnInit {

  private Ticket: any;
  public ticket: any;

  constructor(
    private API: Restangular,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.Ticket = this.API.service('tickets');
    this.getTicket(this.route.snapshot.paramMap.get('id'));
  }

  getTicket(id) {

    this.Ticket
      .one(id)
      .get()
      .subscribe((response) => {
        this.ticket = response.ticket;
      });

  }

}
