import { Component, OnInit } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: string
  serverError: string

  constructor(private API: Restangular,
              private router: Router,
              private toastr: ToastrService) { }

  ngOnInit() {
  }

  onSubmit(f) {

    if (f.invalid) {
      return;
    }
    this.API
      .all('auth/password/email')
      .post({ email: this.email })
      .subscribe(() => {
        this.toastr.success('Por favor, buscá en tu email las intrucciones para reiniciar tu contraseña.', '¡Buenísimo!')
        this.router.navigate(['/login']);
      }, (res) => {
        for (var error in Object.keys(res.data.errors)) {
          this.serverError += res.data.errors[error] + ' ';
        }
      });
  }

}
