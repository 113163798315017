import { Component, OnInit } from '@angular/core';
import { AuthService } from 'ng2-ui-auth';
import { Router } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import {PurchaseService} from '../../services/purchase.service';

@Component({
  selector: 'app-tickets-route',
  templateUrl: './tickets-route.component.html',
  styleUrls: ['./tickets-route.component.scss']
})
export class TicketsRouteComponent implements OnInit {

  Routes: any;
  routes: any;

  constructor(private auth: AuthService,
              private router: Router,
              private API: Restangular,
              private purchase: PurchaseService) {

    this.Routes = this.API.service('public/routes');

  }

  ngOnInit() {
    this.getRoutes();
  }

  getRoutes() {

    this.Routes
      .getList()
      .subscribe(response => {
        this.routes = response;
      });

  }

  selectRoute(route) {

    this.purchase.set('route', route);
    this.purchase.navigateToNextStep();

  }

}
