import { Component, OnInit } from '@angular/core';
import { PurchaseDiscountInterface, PurchaseService } from '../../services/purchase.service';

@Component({
  selector: 'app-checkout-coupon',
  templateUrl: './checkout-coupon.component.html',
  styleUrls: ['./checkout-coupon.component.scss']
})
export class CheckoutCouponComponent implements OnInit {

  showCouponInput = false;
  couponCode: string;
  discount: PurchaseDiscountInterface;

  constructor(private purchaseService: PurchaseService) {}

  ngOnInit() {
    this.purchaseService.data$
      .subscribe((data) => {
        this.discount = data.discount;
      });
  }

  redeem() {

    this.purchaseService
      .getDiscount(this.couponCode)
      .subscribe((discount) => this.purchaseService.set('discount', discount));

  }

}
