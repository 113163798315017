import { Component, OnInit } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.scss']
})
export class MyTicketsComponent implements OnInit {

  tickets: object[];

  constructor(private API: Restangular) { }

  ngOnInit() {

    this.getTickets();

  }

  getTickets() {

    this.API
      .service('tickets')
      .getList()
      .subscribe(response => {
        this.tickets = response;
      });


  }

}
